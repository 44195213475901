import React, { useState, useEffect } from "react";
import {
  Typography,
  InputNumber,
  Checkbox,
  Button,
  Table,
  Space,
  Select,
  Row,
  Modal,
  Form,
  Input,
  message,
  Col,
  Popconfirm,
} from "antd";
import debounce from "lodash/debounce";
import {
  DeleteOutlined,
  PlusOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";

import { PieChart, Pie, Cell } from "recharts";

const { Option } = Select;
const nonce = window.wpRestNonce;

const MAX_DAYS = 10;

const COLORS = ["#FA574F", "#599DCC", "#64BB63", "#4caf50"];

const MyTable = () => {
  const [dayTables, setDayTables] = useState([
    {
      day: 1,
      meals: [
        {
          mealId: Date.now(),
          mealNumber: 1,
          products: [
            {
              key: Date.now(),
              product: "",
              quantity: 100,
              unit: "g",
              calories: 0,
              carbs: 0,
              protein: 0,
              fats: 0,
              baseQuantity: 100,
            },
          ],
        },
      ],
    },
  ]);
  const [currentDay, setCurrentDay] = useState(1);
  const [productOptions, setProductOptions] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);

  const [copiedProducts, setCopiedProducts] = useState([]);
  const [copiedMealIndex, setCopiedMealIndex] = useState(null);
  const [canPaste, setCanPaste] = useState(false);

  const [newProduct, setNewProduct] = useState({
    category: "",
    name: "",
    unit: "g",
    quantity: 100,
    calories: 0,
    protein: 0,
    fats: 0,
    carbs: 0,
  });

  const fetchProducts = async () => {
    try {
      const productsResponse = await fetch("/wp-json/custom-system/v1/items", {
        headers: {
          "X-WP-Nonce": nonce,
        },
      });
      const productsData = await productsResponse.json();
      setAllProducts(productsData);
      setProductOptions(
        productsData.map((product) => ({ value: product.Product }))
      );

      // Fetch categories from the API
      const categoriesResponse = await fetch(
        "/wp-json/custom-system/v1/get-custom-categories",
        {
          headers: {
            "X-WP-Nonce": nonce,
          },
        }
      );
      const categoriesData = await categoriesResponse.json();
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching product list or categories:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    const checkPasteData = async () => {
      try {
        const response = await fetch(
          "/wp-json/custom-app/v1/check-paste-data",
          {
            headers: { "X-WP-Nonce": nonce },
          }
        );
        if (response.ok) {
          const { hasData } = await response.json();
          setCanPaste(hasData);
        } else {
          setCanPaste(false);
        }
      } catch (error) {
        console.error("Klaida tikrinant įklijavimo galimybę:", error);
        setCanPaste(false);
      }
    };

    checkPasteData();
  }, []);

  const handleAddProductModal = () => {
    setIsModalVisible(true);
  };

  const handleCopyMeal = async (mealIndex) => {
    const currentDayTable = dayTables.find((table) => table.day === currentDay);
    if (!currentDayTable) return;

    const mealToCopy = currentDayTable.meals[mealIndex];

    try {
      await fetch("/wp-json/custom-app/v1/clear-copy-meal", {
        method: "POST",
        headers: { "Content-Type": "application/json", "X-WP-Nonce": nonce },
      });

      const response = await fetch("/wp-json/custom-app/v1/copy-meal", {
        method: "POST",
        headers: { "Content-Type": "application/json", "X-WP-Nonce": nonce },
        body: JSON.stringify({ meal: mealToCopy }),
      });

      if (response.ok) {
        message.success("Valgymas nukopijuotas.");
        setCopiedMealIndex(mealIndex);
        setTimeout(() => setCopiedMealIndex(null), 2000);
      } else {
        message.error("Klaida kopijuojant valgymą.");
      }
    } catch (error) {
      console.error("Klaida kopijuojant valgymą:", error);
    }
  };

  const handlePasteMeal = async () => {
    try {
      const response = await fetch("/wp-json/custom-app/v1/paste-meal", {
        method: "POST",
        headers: { "Content-Type": "application/json", "X-WP-Nonce": nonce },
        body: JSON.stringify({ dayIndex: currentDay - 1 }),
      });

      if (response.ok) {
        const { updatedMeal } = await response.json();

        const updatedProducts = updatedMeal.products.map((product) => ({
          ...product,
          key: Date.now() + Math.random(),
        }));

        const newMeal = {
          mealId: Date.now(),
          mealNumber: dayTables[currentDay - 1].meals.length + 1,
          products: updatedProducts,
        };

        const updatedTables = [...dayTables];
        updatedTables[currentDay - 1].meals.push(newMeal);

        setDayTables(updatedTables);
        message.success("Valgymas įklijuotas.");
      } else {
        message.error("Nepavyko įklijuoti valgymo.");
      }
    } catch (error) {
      console.error("Klaida įklijuojant valgymą:", error);
    }
  };

  const handleModalOk = async () => {
    const productExists = allProducts.some(
      (product) =>
        product.Product.toLowerCase() === newProduct.name.toLowerCase()
    );

    if (productExists) {
      message.error("Produktas su tokiu pavadinimu jau egzistuoja.");
      return;
    }

    try {
      const response = await fetch(
        "/wp-json/custom-system/v1/add-custom-product",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-WP-Nonce": nonce,
          },
          body: JSON.stringify(newProduct),
        }
      );

      if (response.ok) {
        const addedProduct = await response.json();

        await fetchProducts();

        setNewProduct({
          category: "",
          name: "",
          quantity: 0,
          calories: 0,
          protein: 0,
          fats: 0,
          carbs: 0,
        });
        setIsModalVisible(false);

        message.success("Produktas sėkmingai pridėtas.");
      } else {
        console.error("Nepavyko pridėti produkto.");
      }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (field, value) => {
    setNewProduct((prev) => ({ ...prev, [field]: value }));
  };

  const saveUserData = debounce(async (data) => {
    try {
      const response = await fetch("/wp-json/custom-system/v1/save-user-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-WP-Nonce": nonce,
        },
        body: JSON.stringify({ dayTables: data }),
      });

      const result = await response.json();

      if (!response.ok) {
        console.error("Response error:", result);
        throw new Error("Failed to save data");
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  }, 500);

  useEffect(() => {
    saveUserData(dayTables);
  }, [dayTables]);

  useEffect(() => {
    const fetchSavedData = async () => {
      try {
        const response = await fetch(
          "/wp-json/custom-system/v1/get-user-data",
          {
            headers: {
              "X-WP-Nonce": nonce,
            },
          }
        );
        if (response.ok) {
          const savedData = await response.json();
          if (savedData && savedData.dayTables) {
            setDayTables(savedData.dayTables);
          }
        } else {
          console.error("Failed to fetch saved data");
        }
      } catch (error) {
        console.error("Error fetching saved data:", error);
      }
    };
    fetchSavedData();
  }, []);

  useEffect(() => {
    fetch("/wp-json/custom-system/v1/items", {
      headers: {
        "X-WP-Nonce": nonce,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAllProducts(data);
        setProductOptions(data.map((product) => ({ value: product.Product })));
      })
      .catch((error) => console.error("Error fetching product list:", error));
  }, []);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleAddDay = () => {
    if (dayTables.length >= MAX_DAYS) {
      return;
    }

    const newDay = {
      day: dayTables.length + 1,
      meals: [
        {
          mealId: Date.now(),
          mealNumber: 1,
          products: [
            {
              key: Date.now(),
              product: "",
              quantity: 100,
              unit: "g",
              calories: 0,
              carbs: 0,
              protein: 0,
              fats: 0,
              baseQuantity: 100,
            },
          ],
        },
      ],
    };

    setDayTables((prev) => [...prev, newDay]);
    setCurrentDay(newDay.day);
  };

  const handleSelectDay = (day) => {
    setCurrentDay(day);
  };

  const removeDiacritics = (text) => {
    return text
      .normalize("NFD") // Suskaido į bazines raides ir diakritinius ženklus
      .replace(/[\u0300-\u036f]/g, ""); // Pašalina diakritinius ženklus
  };

  const handleSearch = debounce((value) => {
    const normalizedSearch = removeDiacritics(value.toLowerCase());

    const filteredOptions = allProducts
      .filter((product) =>
        removeDiacritics(product.Product.toLowerCase()).includes(
          normalizedSearch
        )
      )
      .map((product) => ({ value: product.Product }));

    setProductOptions(filteredOptions);
  }, 300);

  const handleAddMeal = (dayIndex) => {
    const updatedTables = [...dayTables];
    const currentDayTable = updatedTables[dayIndex];

    if (currentDayTable) {
      const newMeal = {
        mealId: Date.now(),
        mealNumber: currentDayTable.meals.length + 1,
        products: [
          {
            key: Date.now(),
            product: "",
            quantity: 100,
            unit: "g",
            calories: 0,
            carbs: 0,
            protein: 0,
            fats: 0,
            baseQuantity: 100,
          },
        ],
      };
      currentDayTable.meals.push(newMeal);
    }

    setDayTables(updatedTables);
  };

  const handleAddProduct = (mealIndex) => {
    const newProduct = {
      key: Date.now(),
      product: "",
      quantity: 100,
      unit: "g",
      calories: 0,
      carbs: 0,
      protein: 0,
      fats: 0,
      baseQuantity: 100,
    };
    const updatedTables = [...dayTables];
    const currentDayTable = updatedTables.find(
      (table) => table.day === currentDay
    );
    if (currentDayTable) {
      currentDayTable.meals[mealIndex].products.push(newProduct);
    }
    setDayTables(updatedTables);
  };

  const handleRemoveProduct = (mealIndex, productIndex) => {
    const updatedTables = [...dayTables];
    const currentDayTable = updatedTables.find(
      (table) => table.day === currentDay
    );
    if (currentDayTable) {
      currentDayTable.meals[mealIndex].products = currentDayTable.meals[
        mealIndex
      ].products.filter((_, index) => index !== productIndex);
    }
    setDayTables(updatedTables);
  };

  const moveMealUp = (dayIndex, mealIndex) => {
    const updatedTables = [...dayTables];
    const dayTable = updatedTables[dayIndex];

    if (mealIndex > 0) {
      const meals = dayTable.meals;
      [meals[mealIndex], meals[mealIndex - 1]] = [
        meals[mealIndex - 1],
        meals[mealIndex],
      ];

      meals.forEach((meal, index) => {
        meal.mealNumber = index + 1;
      });

      setDayTables(updatedTables);
    }
  };

  const moveMealDown = (dayIndex, mealIndex) => {
    const updatedTables = [...dayTables];
    const dayTable = updatedTables[dayIndex];

    if (mealIndex < dayTable.meals.length - 1) {
      const meals = dayTable.meals;
      [meals[mealIndex], meals[mealIndex + 1]] = [
        meals[mealIndex + 1],
        meals[mealIndex],
      ];

      meals.forEach((meal, index) => {
        meal.mealNumber = index + 1;
      });

      setDayTables(updatedTables);
    }
  };

  const handleRemoveMeal = (dayIndex, mealIndex) => {
    const updatedTables = [...dayTables];
    const currentDayTable = updatedTables[dayIndex];

    if (currentDayTable) {
      currentDayTable.meals = currentDayTable.meals.filter(
        (_, index) => index !== mealIndex
      );

      currentDayTable.meals = currentDayTable.meals.map((meal, index) => ({
        ...meal,
        mealNumber: index + 1,
      }));
    }

    setDayTables(updatedTables);
  };

  const handleRemoveDay = (dayIndex) => {
    const updatedTables = dayTables.filter((_, index) => index !== dayIndex);

    // Perskaičiuojame dienų numerius
    const recalculatedTables = updatedTables.map((table, index) => ({
      ...table,
      day: index + 1, // Naujas dienos numeris pagal seką
    }));

    setDayTables(recalculatedTables);
    setCurrentDay(recalculatedTables.length > 0 ? 1 : 0); // Nustatome aktyvią dieną į pirmą, jei bent viena diena liko
  };

  const handleProductSelect = (value, dayIndex, mealIndex, productIndex) => {
    const selectedProduct = allProducts.find(
      (product) => product.Product === value
    );

    if (selectedProduct) {
      const updatedTables = [...dayTables];
      const dayTable = updatedTables[dayIndex];

      if (
        !dayTable ||
        !dayTable.meals[mealIndex] ||
        !dayTable.meals[mealIndex].products[productIndex]
      ) {
        console.error("Nepavyko rasti dayTable arba jo struktūros komponentų.");
        return;
      }

      const quantity = selectedProduct.Quantity || 100; // Numatytasis kiekis, jei nenurodytas
      const multiplier = quantity / selectedProduct.Quantity;

      dayTable.meals[mealIndex].products[productIndex] = {
        ...dayTable.meals[mealIndex].products[productIndex],
        product: selectedProduct.Product,
        quantity,
        unit: quantity <= 5 ? "vnt" : "g",
        calories: selectedProduct.Calories || 0,
        carbs: selectedProduct.Carbohydrates || 0,
        protein: selectedProduct.Proteins || 0,
        fats: selectedProduct.Fats || 0,
        baseQuantity: selectedProduct.Quantity || 1,
        baseCalories: selectedProduct.Calories || 0,
        baseCarbs: selectedProduct.Carbohydrates || 0,
        baseProtein: selectedProduct.Proteins || 0,
        baseFats: selectedProduct.Fats || 0,
      };

      setDayTables(updatedTables);
    }
  };

  const handleSaveQuantity = (dayIndex, mealIndex, productIndex, value) => {
    console.log("Handling Save Quantity:");
    console.log(
      "dayIndex:",
      dayIndex,
      "mealIndex:",
      mealIndex,
      "productIndex:",
      productIndex,
      "value:",
      value
    );

    const updatedTables = [...dayTables];
    const dayTable = updatedTables[dayIndex];

    if (!dayTable || !dayTable.meals[mealIndex]) {
      console.error(
        "dayTable or meal is undefined for indices:",
        dayIndex,
        mealIndex
      );
      return;
    }

    const product = dayTable.meals[mealIndex].products[productIndex];
    if (!product) {
      console.error("Product is undefined for productIndex:", productIndex);
      return;
    }

    console.log("Product details:", product);

    // Add checks for each property
    const baseCalories = product.baseCalories || 0;
    const baseCarbs = product.baseCarbs || 0;
    const baseProtein = product.baseProtein || 0;
    const baseFats = product.baseFats || 0;
    const baseQuantity = product.baseQuantity || 1; // Avoid division by zero

    const multiplier = value / baseQuantity;

    dayTable.meals[mealIndex].products[productIndex] = {
      ...product,
      quantity: value,
      unit: value <= 5 ? "vnt" : "g",
      calories: Math.round(baseCalories * multiplier),
      carbs: Math.round(baseCarbs * multiplier * 10) / 10,
      protein: Math.round(baseProtein * multiplier * 10) / 10,
      fats: Math.round(baseFats * multiplier * 10) / 10,
    };

    console.log(
      "Updated product details:",
      dayTable.meals[mealIndex].products[productIndex]
    );

    setDayTables(updatedTables);
  };

  const calculateTotals = (products) => {
    return products.reduce(
      (totals, item) => {
        totals.calories += Math.round(parseFloat(item.calories) || 0);
        totals.carbs += Math.round(parseFloat(item.carbs) || 0);
        totals.protein += Math.round(parseFloat(item.protein) || 0);
        totals.fats += Math.round(parseFloat(item.fats) || 0);
        return totals;
      },
      { calories: 0, carbs: 0, protein: 0, fats: 0 }
    );
  };

  const calculateDailyTotals = (dayTable) => {
    return dayTable.meals.reduce(
      (totals, meal) => {
        meal.products.forEach((product) => {
          totals.calories += parseFloat(product.calories) || 0;
          totals.carbs += parseFloat(product.carbs) || 0;
          totals.protein += parseFloat(product.protein) || 0;
          totals.fats += parseFloat(product.fats) || 0;
        });
        return totals;
      },
      { calories: 0, carbs: 0, protein: 0, fats: 0 }
    );
  };

  const formatNumber = (number) =>
    number % 1 === 0 ? number : number.toFixed(1);

  const columns = [
    {
      dataIndex: "actions",
      width: 25,
      render: (_, record) => (
        <div className="action-wrapper">
          <Popconfirm
            title="Ar tikrai norite pašalinti šį produktą?"
            onConfirm={() =>
              handleRemoveProduct(
                record.dayIndex,
                record.mealIndex,
                record.productIndex
              )
            }
            okText="Taip"
            cancelText="Ne"
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              className="delete-button"
              danger
            />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: "Produkto pavadinimas",
      dataIndex: "product",
      render: (text, record) => (
        <Select
          showSearch
          style={{ width: "100%" }}
          value={record.product || undefined}
          placeholder="Pasirinkite produktą"
          onSearch={(value) => handleSearch(value)}
          onSelect={(value) =>
            handleProductSelect(
              value,
              record.dayIndex,
              record.mealIndex,
              record.productIndex
            )
          }
          filterOption={false}
        >
          {productOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.value}
            </Option>
          ))}
        </Select>
      ),
      onCell: (record) => ({
        dayIndex: record.dayIndex,
        mealIndex: record.mealIndex,
        productIndex: record.productIndex,
      }),
    },
    {
      title: "Kiekis",
      dataIndex: "quantity",
      render: (text, record) => (
        <InputNumber
          min={0}
          value={record.quantity}
          addonAfter={record.unit}
          onChange={(value) =>
            handleSaveQuantity(
              record.dayIndex,
              record.mealIndex,
              record.productIndex,
              value
            )
          }
          style={{ width: "100px" }}
          disabled={!record.product}
        />
      ),
      onCell: (record) => ({
        dayIndex: record.dayIndex,
        mealIndex: record.mealIndex,
        productIndex: record.productIndex,
      }),
    },
    {
      title: "Kalorijos",
      dataIndex: "calories",
      render: (text) => (
        <InputNumber value={text} disabled style={{ width: "80px" }} />
      ),
    },
    {
      title: "Angliavandeniai",
      dataIndex: "carbs",
      render: (text) => (
        <InputNumber value={text} disabled style={{ width: "80px" }} />
      ),
    },
    {
      title: "Baltymai",
      dataIndex: "protein",
      render: (text) => (
        <InputNumber value={text} disabled style={{ width: "80px" }} />
      ),
    },
    {
      title: "Riebalai",
      dataIndex: "fats",
      render: (text) => (
        <InputNumber value={text} disabled style={{ width: "80px" }} />
      ),
    },
  ];

  const currentDayTable = dayTables.find((table) => table.day === currentDay);
  const currentDayTotals = currentDayTable
    ? calculateDailyTotals(currentDayTable)
    : { calories: 0, carbs: 0, protein: 0, fats: 0 };

  // Apskaičiuojame kalorijas
  const kcalFromCarbs = currentDayTotals.carbs * 4;
  const kcalFromProtein = currentDayTotals.protein * 4;
  const kcalFromFats = currentDayTotals.fats * 9;

  const totalKcal = kcalFromCarbs + kcalFromProtein + kcalFromFats;

  const normalizePercentages = (percentages) => {
    const total = percentages.reduce((sum, p) => sum + Math.floor(p), 0);
    const difference = 100 - total;

    // Pridėti likutį prie didžiausio procento
    const maxIndex = percentages.indexOf(Math.max(...percentages));
    percentages[maxIndex] += difference;

    return percentages.map(Math.round); // Suapvalinkite viską
  };

  const percentages =
    totalKcal > 0
      ? normalizePercentages([
          (kcalFromCarbs / totalKcal) * 100,
          (kcalFromProtein / totalKcal) * 100,
          (kcalFromFats / totalKcal) * 100,
        ])
      : [0, 0, 0];

  const chartData = [
    {
      name: "Angliavandeniai",
      value: currentDayTotals.carbs,
      color: COLORS[0],
    },
    { name: "Baltymai", value: currentDayTotals.protein, color: COLORS[1] },
    { name: "Riebalai", value: currentDayTotals.fats, color: COLORS[2] },
  ];

  const secondChartData = [
    {
      name: "Angliavandeniai",
      value: percentages[0],
      color: COLORS[0],
      grams: currentDayTotals.carbs,
    },
    {
      name: "Baltymai",
      value: percentages[1],
      color: COLORS[1],
      grams: currentDayTotals.protein,
    },
    {
      name: "Riebalai",
      value: percentages[2],
      color: COLORS[2],
      grams: currentDayTotals.fats,
    },
  ];

  const roundNumber = (num) => Math.round(num);

  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: "20px" }}>
        <Col>
          <Typography.Title level={4}>Pridėti dienos valgymus</Typography.Title>
        </Col>
        <Col>
          <Space direction="horizontal" size="middle">
            <Button
              className="primary-button"
              onClick={handleAddDay}
              icon={<PlusOutlined />}
            >
              Nauja diena
            </Button>
            <Button
              className="primary-button"
              onClick={handleAddProductModal}
              icon={<PlusOutlined />}
            >
              Naujas produktas
            </Button>
          </Space>
        </Col>
      </Row>

      <Row style={{ marginBottom: "20px" }}>
        <Col
          flex="auto"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <Space>
              {dayTables.map((table, index) => (
                <Button
                  className={`primary-button ${
                    table.day === currentDay ? "active-day" : ""
                  }`}
                  key={table.day}
                  type={table.day === currentDay ? "primary" : "default"}
                  onClick={() => handleSelectDay(table.day)}
                  style={{ maxWidth: "76px" }}
                >
                  Diena {table.day}
                </Button>
              ))}
            </Space>
          </div>
          {dayTables.length > 1 && (
            <Popconfirm
              title="Ar tikrai norite pašalinti pasirinktą dieną?"
              onConfirm={() => handleRemoveDay(currentDay - 1)}
              okText="Taip"
              cancelText="Ne"
            >
              <Button style={{ marginLeft: 10 }} className="danger-button">
                Ištrinti dieną
              </Button>
            </Popconfirm>
          )}
        </Col>
      </Row>

      {currentDayTable && (
        <>
          {currentDayTable.meals.map((meal, mealIndex) => {
            const totals = calculateTotals(meal.products);
            return (
              <div key={meal.mealId} style={{ marginBottom: "20px" }}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <Typography.Title level={5}>
                      {meal.mealNumber}-as valgymas
                    </Typography.Title>
                  </Col>

                  <Col>
                    <Space style={{ marginBottom: 3 }}>
                      <Button
                        className="primary-button"
                        onClick={() => handleCopyMeal(mealIndex)}
                      >
                        {copiedMealIndex === mealIndex
                          ? "Nukopijuota"
                          : "Kopijuoti valgymą"}
                      </Button>

                      {mealIndex > 0 && (
                        <Button
                          style={{
                            backgroundColor: "#61988e",
                            border: "none",
                          }}
                          size="normal"
                          icon={<UpOutlined style={{ color: "#fff" }} />} // Ikonos spalva balta
                          onClick={() => moveMealUp(currentDay - 1, mealIndex)}
                        />
                      )}
                      {mealIndex < currentDayTable.meals.length - 1 && (
                        <Button
                          style={{
                            backgroundColor: "#61988e",
                            border: "none",
                            padding: 3,
                          }}
                          size="normal"
                          icon={<DownOutlined style={{ color: "#fff" }} />} // Ikonos spalva balta
                          onClick={() =>
                            moveMealDown(currentDay - 1, mealIndex)
                          }
                        />
                      )}
                    </Space>
                  </Col>
                </Row>
                <Table
                  columns={columns} // Įsitikinkite, kad stulpelių konfigūracija suderinta su duomenimis
                  dataSource={meal.products.map((item, productIndex) => ({
                    ...item,
                    dayIndex: currentDayTable.day - 1,
                    mealIndex,
                    productIndex,
                  }))}
                  rowKey="key"
                  pagination={false}
                  scroll={{ x: "max-content" }}
                  summary={
                    isDesktop
                      ? () => (
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={2}>
                              <Typography.Text strong>Iš viso:</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              <Typography.Text strong>
                                {totals.quantity}
                              </Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                              <Typography.Text strong>
                                {totals.calories} kcal
                              </Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                              <Typography.Text strong>
                                {totals.carbs} g
                              </Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                              <Typography.Text strong>
                                {totals.protein} g
                              </Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={5}>
                              <Typography.Text strong>
                                {totals.fats} g
                              </Typography.Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )
                      : null
                  }
                />

                <Space
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    className="primary-button"
                    onClick={() => handleAddProduct(mealIndex)}
                  >
                    Naujas produktas
                  </Button>
                  {currentDayTable.meals.length > 1 && (
                    <Popconfirm
                      title="Ar tikrai norite pašalinti šį valgymą?"
                      onConfirm={() =>
                        handleRemoveMeal(currentDay - 1, mealIndex)
                      }
                      okText="Taip"
                      cancelText="Ne"
                    >
                      <Button className="danger-button">
                        Ištrinti šį valgymą
                      </Button>
                    </Popconfirm>
                  )}
                </Space>
              </div>
            );
          })}

          <Button
            type="dashed"
            size="large"
            icon={<PlusOutlined />}
            onClick={() => handleAddMeal(currentDay - 1)}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Pridėti kitą valgymą
          </Button>

          <Button
            type="dashed"
            size="large"
            onClick={() => handlePasteMeal()}
            style={{
              width: "100%",
              display: "flex",
              marginTop: "10px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Įklijuoti valgymą
          </Button>

          <Typography.Title
            style={{ marginTop: 15, textAlign: "center" }}
            level={4}
          >
            Visos dienos:
          </Typography.Title>
          {currentDayTable && currentDayTable.meals.length > 1 && (
            <Row
              justify="center"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
                marginBottom: "10px",
                backgroundColor: "#f0f2f5",
                borderRadius: "8px",
                textAlign: "left",
              }}
            >
              <Col>
                <Row
                  gutter={[16, 8]}
                  style={{
                    fontSize: "clamp(10px, 2.5vw, 14px)",
                    marginTop: "10px",
                  }}
                  justify={isDesktop ? "start" : "center"}
                >
                  <Col>
                    <Typography.Text>Kcal: </Typography.Text>
                    <span
                      style={{
                        backgroundColor: "#f5f5f5",
                        border: "1px solid #d9d9d9",
                        padding: "2px 5px",
                        borderRadius: "4px",
                        color: "#000",
                      }}
                    >
                      {formatNumber(Math.round(currentDayTotals.calories))}
                    </span>
                  </Col>
                  <Col>
                    <Typography.Text>Angl: </Typography.Text>
                    <span
                      style={{
                        backgroundColor: "#fa574f",
                        border: "none",
                        padding: "2px 5px",
                        borderRadius: "4px",
                        color: "#fff",
                      }}
                    >
                      {formatNumber(Math.round(currentDayTotals.carbs))}
                    </span>
                  </Col>
                  <Col>
                    <Typography.Text>Balt: </Typography.Text>
                    <span
                      style={{
                        backgroundColor: "#599dcc",
                        border: "none",
                        padding: "2px 5px",
                        borderRadius: "4px",
                        color: "#fff",
                      }}
                    >
                      {formatNumber(Math.round(currentDayTotals.protein))}
                    </span>
                  </Col>
                  <Col>
                    <Typography.Text>Rieb: </Typography.Text>
                    <span
                      style={{
                        backgroundColor: "#64bb63",
                        border: "none",
                        padding: "2px 5px",
                        borderRadius: "4px",
                        color: "#fff",
                      }}
                    >
                      {formatNumber(Math.round(currentDayTotals.fats))}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Typography.Title
            style={{ marginTop: 15, textAlign: "center" }}
            level={4}
          >
            Maisto medžiagų pasiskirstymas:
          </Typography.Title>
          <Row
            justify="space-between"
            gutter={[32, 32]}
            style={{
              marginTop: "-20px",
              textAlign: "center",
            }}
          >
            <Col
              xs={24}
              lg={24}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div style={{ position: "relative", width: 350, height: 350 }}>
                <PieChart width={350} height={350}>
                  <Pie
                    data={secondChartData}
                    cx="50%"
                    cy="50%"
                    innerRadius={100} // Padidintas vidinis spindulys
                    outerRadius={140} // Padidintas išorinis spindulys
                    dataKey="value"
                    label={({
                      cx,
                      cy,
                      midAngle,
                      innerRadius,
                      outerRadius,
                      value,
                    }) => {
                      const radius =
                        innerRadius + (outerRadius - innerRadius) / 2; // Teksto pozicijos spindulys
                      const x =
                        cx + radius * Math.cos(-midAngle * (Math.PI / 180)); // X koord.
                      const y =
                        cy + radius * Math.sin(-midAngle * (Math.PI / 180)); // Y koord.

                      return (
                        <text
                          x={x}
                          y={y}
                          fill="white"
                          textAnchor="middle"
                          dominantBaseline="middle"
                          style={{
                            fontSize: "14px", // Padidintas etiketės dydis
                            fontWeight: "bold",
                          }}
                        >
                          {`${roundNumber(value)}%`}
                        </text>
                      );
                    }}
                    labelLine={false} // Paslepia linijas nuo etiketės
                  >
                    {secondChartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                </PieChart>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "24px", // Padidintas centrinio teksto dydis
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {roundNumber(currentDayTotals.calories)} kcal
                </div>
              </div>
            </Col>
          </Row>

          <Modal
            title="Pridėti naują produktą"
            visible={isModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            okText="Pridėti"
            cancelText="Atšaukti"
            style={{ marginTop: 45 }}
            footer={[
              <Button key="back" onClick={handleModalCancel}>
                Atšaukti
              </Button>,
              <Button
                key="submit"
                onClick={handleModalOk}
                className="primary-button"
                type="primary"
              >
                Pridėti
              </Button>,
            ]}
          >
            <Form layout="vertical">
              <Form.Item label="Kategorija">
                <Select
                  value={newProduct.category}
                  onChange={(value) => handleInputChange("category", value)}
                  placeholder="Pasirinkite kategoriją"
                >
                  {categories.map((category) => (
                    <Option key={category} value={category}>
                      {category}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Produkto pavadinimas">
                <Input
                  value={newProduct.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                  placeholder="Įveskite produkto pavadinimą"
                />
              </Form.Item>

              <Form.Item label="Kiekis">
                {/* Pradinis kiekis nustatomas pagal unit */}
                <InputNumber
                  value={newProduct.unit === "g" ? 100 : 1}
                  disabled
                  style={{ width: "100%" }}
                  addonAfter={newProduct.unit === "g" ? "g" : "vnt"}
                />
                {/* Checkbox, kuris perjungia tarp gramų ir vienetų */}
                <Checkbox
                  checked={newProduct.unit === "vnt"} // Parinktis "Skaičiuoti vienetais" iš pradžių NEPAŽYMĖTA
                  onChange={(e) => {
                    const isUnits = e.target.checked; // Ar checkbox pažymėtas
                    if (isUnits) {
                      handleInputChange("unit", "vnt");
                      handleInputChange("quantity", 1); // Nustatyti kiekį 1 vnt
                    } else {
                      handleInputChange("unit", "g");
                      handleInputChange("quantity", 100); // Nustatyti kiekį 100 g
                    }
                  }}
                  style={{ marginTop: "8px" }}
                >
                  Skaičiuoti vienetais
                </Checkbox>
                {/* Papildomi paaiškinimai, priklausomai nuo pasirinkimo */}
                {newProduct.unit === "g" && (
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "12px" }}
                  >
                    Kiekis skaičiuojamas kaip gramai (100 g).
                  </Typography.Text>
                )}
                {newProduct.unit === "vnt" && (
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "12px" }}
                  >
                    Kiekis skaičiuojamas kaip vienetai (1 vnt).
                  </Typography.Text>
                )}
              </Form.Item>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Kalorijos">
                    <InputNumber
                      min={0}
                      value={newProduct.calories}
                      onChange={(value) => handleInputChange("calories", value)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Baltymai (g)">
                    <InputNumber
                      min={0}
                      value={newProduct.protein}
                      onChange={(value) => handleInputChange("protein", value)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Riebalai (g)">
                    <InputNumber
                      min={0}
                      value={newProduct.fats}
                      onChange={(value) => handleInputChange("fats", value)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Angliavandeniai (g)">
                    <InputNumber
                      min={0}
                      value={newProduct.carbs}
                      onChange={(value) => handleInputChange("carbs", value)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </>
      )}
    </div>
  );
};

export default MyTable;
