import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Typography,
  Alert,
  Button,
  Row,
  Col,
  Modal,
  message,
  Form,
  Upload,
  Input,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Title as ChartTitle, // Pervadiname Title į ChartTitle
} from "chart.js";

import {
  WeightIcon,
  BicepIcon,
  WaistIcon,
  ChestIcon,
  ShouldersIcon,
  BellyIcon,
  HipsIcon,
} from "./Icons";

import {
  FaWalking, // Blauzda
} from "react-icons/fa";

import image from "./images/information.png";

const icons = {
  weight: WeightIcon,
  chest: ChestIcon,
  shoulders: ShouldersIcon,
  biceps: BicepIcon,
  waist: WaistIcon,
  belly: BellyIcon,
  hips: HipsIcon,
  thigh: FaWalking,
  calf: FaWalking,
};

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  ChartTitle // Naudojame pervadintą ChartJS Title komponentą
);

const { Title, Text } = Typography;

const ResponsiveImage = styled.img`
  width: 100%;
  height: 250px;
  border-radius: 8px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 200px;
  }
`;

const MeasurementTable = () => {
  const chartRef = useRef(null);
  const [data, setData] = useState([]);
  const [isInitialModalVisible, setIsInitialModalVisible] = useState(false);
  const [isWeeklyModalVisible, setIsWeeklyModalVisible] = useState(false);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [changes, setChanges] = useState(null); // Pokyčių duomenys

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null); // Aktyvi nuotrauka
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [lastMeasurementChanges, setLastMeasurementChanges] = useState(null); // Pokyčiai nuo paskutinio matavimo
  const [totalChanges, setTotalChanges] = useState(null); // Bendri pokyčiai

  const [user, setUser] = useState(null);
  const [userImages, setUserImages] = useState(null);

  const [firstImageUrl, setFirstImageUrl] = useState(null);
  const [lastImageUrl, setLastImageUrl] = useState(null);

  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [deleteRecordKey, setDeleteRecordKey] = useState(null);

  const images = [firstImageUrl, lastImageUrl].filter((url) => url); // Turimos nuotraukos

  const showAddInitialValuesModal = () => setIsInitialModalVisible(true);
  const handleInitialModalCancel = () => setIsInitialModalVisible(false);

  const showAddWeeklyValuesModal = () => setIsWeeklyModalVisible(true);
  const handleWeeklyModalCancel = () => setIsWeeklyModalVisible(false);

  const [isGalleryLightboxOpen, setIsGalleryLightboxOpen] = useState(false);
  const [currentGalleryImage, setCurrentGalleryImage] = useState(null);
  const [currentGalleryImageIndex, setCurrentGalleryImageIndex] = useState(0);

  const maxWeight = Math.max(...data.map((item) => item.weight || 0), 0);

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setCurrentImage(images[index]);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const moveToNextImage = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(nextIndex);
    setCurrentImage(images[nextIndex]);
  };

  const moveToPrevImage = () => {
    const prevIndex = (currentImageIndex - 1 + images.length) % images.length;
    setCurrentImageIndex(prevIndex);
    setCurrentImage(images[prevIndex]);
  };

  const openGalleryLightbox = (index) => {
    setCurrentGalleryImageIndex(index);
    setCurrentGalleryImage(allImages[index].url); // Naudoja nuotraukų sąrašą
    setIsGalleryLightboxOpen(true);
  };

  const closeGalleryLightbox = () => {
    setIsGalleryLightboxOpen(false);
  };

  const moveToNextGalleryImage = () => {
    const nextIndex = (currentGalleryImageIndex + 1) % allImages.length;
    setCurrentGalleryImageIndex(nextIndex);
    setCurrentGalleryImage(allImages[nextIndex].url);
  };

  const moveToPrevGalleryImage = () => {
    const prevIndex =
      (currentGalleryImageIndex - 1 + allImages.length) % allImages.length;
    setCurrentGalleryImageIndex(prevIndex);
    setCurrentGalleryImage(allImages[prevIndex].url);
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [data]);

  useEffect(() => {
    if (data.length > 1) {
      // Pokyčiai nuo paskutinio matavimo
      const newLastChanges = calculateChange(data);
      setLastMeasurementChanges(newLastChanges);

      // Viso laiko pokyčiai
      const newTotalChanges = calculateTotalChange(data);
      setTotalChanges(newTotalChanges);
    }
  }, [data]);

  useEffect(() => {
    console.log("Data po fetch:", data);
  }, [data]);

  useEffect(() => {
    console.log("Vartotojas:", user);
  }, [user]);

  useEffect(() => {
    const initializeUser = async () => {
      const loggedInUser = await fetchUser();
      if (loggedInUser) setUser(loggedInUser);
    };
    initializeUser();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      // Pirmos įrašo pirmoji nuotrauka
      const firstImage = data[0]?.image0 || null;
      setFirstImageUrl(firstImage);

      // Paskutinio įrašo pirmoji nuotrauka
      const lastImage = data[data.length - 1]?.image0 || null;
      setLastImageUrl(lastImage);
    }
  }, [data]);

  const fetchUser = async () => {
    try {
      const response = await fetch("/wp-json/custom-endpoint/v1/user", {
        headers: {
          "X-WP-Nonce": window.wpRestNonce,
        },
        credentials: "include",
      });

      if (response.ok) {
        return await response.json();
      } else {
        console.warn("Naudotojas neprisijungęs.");
        return null;
      }
    } catch (error) {
      console.error("Klaida gaunant naudotojo informaciją:", error);
      return null;
    }
  };

  const fetchUserImages = async () => {
    try {
      const response = await fetch("/wp-json/custom-endpoint/v1/user-images", {
        headers: {
          "X-WP-Nonce": window.wpRestNonce, // WP Nonce saugumo užtikrinimui
        },
        credentials: "include",
      });

      if (response.ok) {
        const images = await response.json();
        console.log("Vartotojo nuotraukos:", images);
        return images;
      } else {
        console.error("Nepavyko gauti nuotraukų:", await response.text());
        return [];
      }
    } catch (error) {
      console.error("Klaida užklausiant API:", error);
      return [];
    }
  };

  useEffect(() => {
    const loadImages = async () => {
      const userImages = await fetchUserImages();
      setUserImages(userImages);
    };

    loadImages();
  }, []);

  const checkInitialMeasurements = async () => {
    try {
      const response = await fetch("/wp-json/custom-endpoint/v1/measurements", {
        headers: {
          "X-WP-Nonce": window.wpRestNonce,
        },
        credentials: "include",
      });

      if (response.ok) {
        const hasMeasurements = await response.json();
        return hasMeasurements;
      } else {
        console.warn("Nepavyko patikrinti pradinės apimties.");
        return false;
      }
    } catch (error) {
      console.error("Klaida tikrinant pradinę apimtį:", error);
      return false;
    }
  };

  const saveMeasurements = async (measurements) => {
    console.log("Siunčiama į API:", measurements);
    try {
      const response = await fetch("/wp-json/custom-endpoint/v1/measurements", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-WP-Nonce": window.wpRestNonce,
        },
        credentials: "include",
        body: JSON.stringify(measurements), // Čia įvyksta klaida
      });

      if (response.ok) {
        return await response.json();
      } else {
        console.error("API klaida:", await response.json());
        return null;
      }
    } catch (error) {
      console.error("Klaida siunčiant užklausą:", error);
      return null;
    }
  };

  const fetchAllMeasurements = async () => {
    try {
      const response = await fetch(
        "/wp-json/custom-endpoint/v1/measurements/all",
        {
          headers: {
            "X-WP-Nonce": window.wpRestNonce,
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const measurements = await response.json();
        return measurements.map((measurement) => ({
          ...measurement,
          created_at: new Date(measurement.created_at).toISOString(), // Paverčiame į ISO formatą
        }));
      } else {
        console.error("Klaida gaunant visus matavimus.");
        return [];
      }
    } catch (error) {
      console.error("Klaida gaunant visus matavimus:", error);
      return [];
    }
  };

  useEffect(() => {
    const initializeMeasurements = async () => {
      if (user) {
        const measurements = await fetchAllMeasurements();
        setData(measurements); // Užpildykite duomenis po sėkmingo gavimo
      }
    };
    initializeMeasurements();
  }, [user]);

  const fieldNamesLT = {
    weight: "Svoris (kg)",
    chest: "Krūtinė (cm)",
    shoulders: "Pečiai (cm)",
    biceps: "Bicepsas (cm)",
    waist: "Talija (cm)",
    belly: "Pilvas (cm)",
    hips: "Klubai (cm)",
    thigh: "Šlaunis (cm)",
    calf: "Blauzda (cm)",
  };

  // Funkcija pokyčių apskaičiavimui
  const calculateChange = (data) => {
    if (!data || data.length < 2) return {};
    const last = data[data.length - 1] || {};
    const prev = data[data.length - 2] || {};
    return Object.keys(last).reduce((acc, key) => {
      if (key !== "week" && key !== "key") {
        acc[key] = (last[key] || 0) - (prev[key] || 0);
      }
      return acc;
    }, {});
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("/wp-json/custom-endpoint/v1/upload-image", {
        method: "POST",
        headers: {
          "X-WP-Nonce": window.wpRestNonce, // Nonce autentifikacijai
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        if (result.url) {
          return result.url; // Grąžiname įkeltos nuotraukos URL
        } else {
          throw new Error("Nuotraukos URL nerastas.");
        }
      } else {
        const error = await response.json();
        console.error("API klaida įkeliant nuotrauką:", error);
        throw new Error("Nepavyko įkelti nuotraukos.");
      }
    } catch (error) {
      console.error("Klaida siunčiant užklausą:", error);
      throw error;
    }
  };

  const saveSingleMeasurement = async (id, field, value) => {
    try {
      const response = await fetch(
        `/wp-json/custom-endpoint/v1/measurements/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            "X-WP-Nonce": window.wpRestNonce, // Pridedame nonce
          },
          credentials: "include",
          body: JSON.stringify({ [field]: value }), // Tik pakeistas laukas
        }
      );

      if (response.ok) {
        const updatedMeasurement = await response.json();
        return updatedMeasurement;
      } else {
        const error = await response.json();
        console.error(
          "Nepavyko išsaugoti pakeitimo:",
          error.message || "Nežinoma klaida"
        );
        return null;
      }
    } catch (error) {
      console.error("Klaida siunčiant PATCH užklausą:", error);
      return null;
    }
  };

  // Funkcija bendriems pokyčiams apskaičiuoti
  const calculateTotalChange = (data) => {
    if (!data || data.length < 2) return {};
    const initial = data[0];
    const latest = data[data.length - 1];
    return Object.keys(initial).reduce((acc, key) => {
      if (key !== "week" && key !== "key") {
        acc[key] = (latest[key] || 0) - (initial[key] || 0);
      }
      return acc;
    }, {});
  };

  const handleAddInitialValues = async (values) => {
    const imageFields = ["image0", "image1", "image2"];
    const imageUrls = {};

    for (const field of imageFields) {
      const fileList = values[field];
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj;
        const url = await uploadImage(file);
        if (url) {
          imageUrls[field] = url;
        } else {
          message.error(`Nepavyko įkelti nuotraukos: ${field}`);
          return;
        }
      }
    }

    // Pašaliname failo objektus
    const sanitizedValues = { ...values };
    imageFields.forEach((field) => delete sanitizedValues[field]);

    const newValues = {
      ...sanitizedValues,
      ...imageUrls,
      user_id: user?.id,
      date: new Date().toISOString(),
    };

    console.log("Sanitized duomenys:", newValues);

    const result = await saveMeasurements(newValues);
    if (result) {
      setData(await fetchAllMeasurements());
      setIsInitialModalVisible(false);
      message.success("Matavimai ir nuotraukos sėkmingai pridėti!");
    } else {
      message.error("Nepavyko pridėti matavimų.");
    }
  };

  const handleAddWeeklyValues = async (values) => {
    const imageFields = ["image0", "image1", "image2"];
    const imageUrls = {};

    for (const field of imageFields) {
      const fileList = values[field];
      if (fileList && fileList.length > 0) {
        const file = fileList[0].originFileObj; // Gaukite originalų failą
        const url = await uploadImage(file); // Naudokite įkėlimo funkciją
        if (url) {
          imageUrls[field] = url; // Išsaugokite URL
        } else {
          message.error(`Nepavyko įkelti nuotraukos: ${field}`);
          return;
        }
      }
    }

    // Pašalinkite failo objektus iš `values`
    const sanitizedValues = { ...values };
    imageFields.forEach((field) => delete sanitizedValues[field]);

    // Sudėkite visus duomenis į vieną objektą
    const newValues = {
      ...sanitizedValues,
      ...imageUrls,
      user_id: user?.id, // Pridėkite naudotojo ID
      date: new Date().toISOString(), // Pridėkite datą
    };

    console.log("Sanitized duomenys:", newValues);

    try {
      const result = await saveMeasurements(newValues); // Išsaugokite matavimus API
      if (result) {
        const measurements = await fetchAllMeasurements(); // Gaukite atnaujintus matavimus
        setData(measurements); // Atnaujinkite duomenų lentelę
        setIsWeeklyModalVisible(false);
        message.success("Matavimai ir nuotraukos sėkmingai pridėti!");
      } else {
        message.error("Nepavyko pridėti matavimų.");
      }
    } catch (error) {
      console.error("Klaida pridedant matavimą:", error);
      message.error("Įvyko klaida pridedant matavimus.");
    }
  };

  const handleCellChange = async (id, dataIndex, value) => {
    const updatedValue = Number(value) || 0;

    // Atnaujinti vietinę būseną ir perskaičiuoti pokyčius
    setData((prev) => {
      const updatedData = prev.map((item) =>
        item.id === id ? { ...item, [dataIndex]: updatedValue } : item
      );
      setLastMeasurementChanges(calculateChange(updatedData));
      setTotalChanges(calculateTotalChange(updatedData));
      return updatedData;
    });

    // Siųsti pakeitimą į serverį
    const result = await saveSingleMeasurement(id, dataIndex, updatedValue);
    if (result) {
    } else {
    }
  };

  const handleDeleteConfirm = (key) => {
    if (!key) {
      return;
    }
    setDeleteRecordKey(key); // Nustatome ID
    setDeleteConfirmVisible(true); // Rodome modalą
  };

  const handleDeleteCancel = () => {
    setDeleteRecordKey(null);
    setDeleteConfirmVisible(false);
  };

  const handleDelete = async () => {
    if (!deleteRecordKey) {
      return;
    }

    // Ištriname lokaliai duomenis prieš serverio užklausą
    setData((prev) => prev.filter((record) => record.id !== deleteRecordKey));

    try {
      const response = await fetch(
        `/wp-json/custom-endpoint/v1/measurements/${deleteRecordKey}`,
        {
          method: "DELETE",
          headers: {
            "X-WP-Nonce": window.wpRestNonce,
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        setDeleteConfirmVisible(false); // Uždarykite modalą
      } else {
        const error = await response.json();
        // Jei serverio klaida, grąžiname duomenis atgal
        fetchAllMeasurements().then((measurements) => setData(measurements));
      }
    } catch (error) {
      console.error("Klaida ištrinant matavimą:", error);
      // Jei klaida, grąžiname duomenis atgal
      fetchAllMeasurements().then((measurements) => setData(measurements));
    }
  };

  const chartData = {
    labels: data.map((item) =>
      new Date(item.created_at).toLocaleDateString("lt-LT", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    ), // Formatuojame datą į suprantamą formatą
    datasets: [
      {
        label: "Svorio kitimas (kg)",
        data: data.map((item) => (item.weight ? item.weight : 0)),
        borderColor: "#61988e",
        backgroundColor: "rgba(97, 152, 142, 0.2)",
        pointBackgroundColor: "#e0f4f1",
        pointBorderColor: "#61988e",
        pointRadius: 5,
        pointHoverRadius: 7,
        tension: 0.4, // Linijos užapvalinimas
      },
    ],
  };

  const getAllImages = () => {
    return data.reduce((allImages, record) => {
      ["image0", "image1", "image2"].forEach((field) => {
        if (record[field]) {
          allImages.push({
            url: record[field],
            id: `${record.id}_${field}`, // Unikalus ID
            date: record.created_at,
          });
        }
      });
      return allImages;
    }, []);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context) {
            return `Data: ${context[0].label}`; // Tooltip pavadinimas (data)
          },
          label: function (context) {
            const value = context.raw; // Konkreti taško reikšmė
            return `Svoris: ${value} kg`; // Rodoma reikšmė su "kg"
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Data",
        },
      },
      y: {
        title: {
          display: true,
          text: "Svoris (kg)",
        },
        min: 0,
        max: maxWeight + 10,
        ticks: {
          stepSize: 10,
        },
      },
    },
  };

  const columns = [
    {
      title: "Data",
      dataIndex: "created_at",
      key: "created_at",
      fixed: "left",
      width: 130,
      render: (text, record, index) => {
        if (index === 0) {
          return <strong>Pirminė apimtis</strong>;
        }

        try {
          const formattedDate = new Date(text).toLocaleDateString("lt-LT", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
          return formattedDate;
        } catch (error) {
          console.error("Invalid date format:", text);
          return "Nežinoma data";
        }
      },
    },
    ...[
      { title: "Svoris (kg)", key: "weight" },
      { title: "Krūtinė (cm)", key: "chest" },
      { title: "Pečiai (cm)", key: "shoulders" },
      { title: "Bicepsas (įtemptas) (cm)", key: "biceps" },
      { title: "Talija (cm)", key: "waist" },
      { title: "Pilvas (cm)", key: "belly" },
      { title: "Klubai (cm)", key: "hips" },
      { title: "Šlaunis (cm)", key: "thigh" },
      { title: "Blauzda (cm)", key: "calf" },
    ].map(({ title, key }) => ({
      title,
      dataIndex: key,
      key,
      width: 100,
      render: (text, record) => (
        <Input
          type="number"
          value={text}
          style={{ width: "100%" }}
          onChange={(e) => handleCellChange(record.id, key, e.target.value)}
        />
      ),
    })),
    {
      title: "Veiksmai",
      key: "actions",
      render: (_, record) => (
        <Button
          type="link"
          danger
          onClick={() => handleDeleteConfirm(record.id)}
        >
          Ištrinti
        </Button>
      ),
    },
  ];

  const allImages = getAllImages();

  return (
    <div
      style={{
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <Row
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={6}></Title>
        <Button className="primary-button" onClick={showAddWeeklyValuesModal}>
          Pridėti matavimą
        </Button>
      </Row>

      {data.length === 0 ? (
        <div style={{ textAlign: "center", padding: "50px 0" }}>
          <Text type="secondary">
            Pradinės apimties nėra, prašome pridėti pradinę apimtį.
          </Text>
          <br />
          <Button
            className="primary-button"
            onClick={showAddInitialValuesModal}
          >
            Pridėti pradinę apimtį
          </Button>
        </div>
      ) : (
        <>
          <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
            <Col xs={24} md={12}>
              <Title level={4}>Svorio kitimas</Title>
              <div style={{ height: "300px" }}>
                <Line ref={chartRef} data={chartData} options={chartOptions} />
              </div>
            </Col>
            <Col xs={24} md={12}>
              <Title level={4}>Mano nuotraukos</Title>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div style={{ textAlign: "center" }}>
                    <Title level={5} className="process-title">
                      Pradinės apimties nuotrauka
                    </Title>
                    {firstImageUrl ? (
                      <ResponsiveImage
                        src={firstImageUrl}
                        alt="Pradinė nuotrauka"
                        onClick={() => openLightbox(0)} // Atidaro Lightbox su pirmąja nuotrauka
                        style={{ cursor: "pointer" }} // Rodo, kad nuotrauka spaudžiama
                      />
                    ) : (
                      <Text type="secondary">Nuotrauka nerasta.</Text>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ textAlign: "center" }}>
                    <Title level={5} className="process-title">
                      Paskutinio matavimo nuotrauka
                    </Title>
                    {lastImageUrl ? (
                      <ResponsiveImage
                        src={lastImageUrl}
                        alt="Paskutinė nuotrauka"
                        onClick={() => openLightbox(1)} // Atidaro Lightbox su antrąja nuotrauka
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <Text type="secondary">Nuotrauka nerasta.</Text>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Title level={4}>Visos nuotraukos</Title>
          <div
            style={{
              display: "flex", // Flexbox išdėstymas
              overflowX: "auto", // Horizontalus slinkimas
              marginBottom: 8,
              gap: "10px", // Tarpas tarp nuotraukų
            }}
          >
            {getAllImages().map((image, index) => (
              <div
                key={image.id}
                style={{
                  position: "relative", // Reikalinga, kad data būtų "overlay"
                  minWidth: "120px", // Užtikriname, kad kortelės būtų vienodo pločio
                  height: "120px", // Vienodo aukščio
                  flexShrink: 0, // Neleisti nuotraukoms susitraukti
                  borderRadius: "12px", // Užapvalinti kampai
                  overflow: "hidden", // Paslepia perteklinį turinį
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Švelnus šešėlis
                }}
              >
                <img
                  src={image.url}
                  alt={`Nuotrauka ${index + 1}`}
                  style={{
                    width: "120px", // Užpildo kortelę
                    height: "120px", // Užpildo kortelę
                    objectFit: "cover", // Proporcingai apkarpyta nuotrauka
                    cursor: "pointer", // Rankos žymeklis
                  }}
                  onClick={() => openGalleryLightbox(index)} // Naudojame galerijos funkciją
                />
                <small
                  style={{
                    position: "absolute", // Užtikrina, kad data būtų "overlay"
                    bottom: "5px", // Padėtis apačioje
                    right: "8px", // Padėtis dešinėje
                    backgroundColor: "rgba(0, 0, 0, 0.6)", // Tamsus fonas
                    color: "#fff", // Teksto spalva
                    fontSize: "12px", // Mažas šriftas
                    padding: "2px 6px", // Šiek tiek vidaus tarpo
                    borderRadius: "8px", // Užapvalinti kampai
                  }}
                >
                  {new Date(image.date).toLocaleDateString()}
                </small>
              </div>
            ))}
          </div>

          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Title level={4}>Pokyčiai nuo praėjusio matavimo</Title>
              <div
                style={{
                  padding: "20px",
                  border: "1px solid #f0f0f0",
                  borderRadius: "8px",
                  background: "#fafafa",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.02)",
                }}
              >
                {lastMeasurementChanges ? (
                  <Row gutter={[16, 16]}>
                    {Object.entries(lastMeasurementChanges)
                      .filter(([key]) => icons[key]) // Filtruojame tik tuos, kurie turi ikoną
                      .map(([key, value]) => {
                        const Icon = icons[key]; // Ikonos komponentas
                        return (
                          <Col xs={12} sm={12} md={8} key={key}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                padding: "10px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "6px",
                                background: "#ffffff",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.04)",
                              }}
                            >
                              {Icon && (
                                <div
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    backgroundColor: "#e0f4f1",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Icon color="#61988e" size="30px" />
                                </div>
                              )}

                              <div>
                                <strong
                                  style={{ fontSize: "12px", display: "block" }}
                                >
                                  {fieldNamesLT[key]}
                                </strong>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color:
                                      value > 0
                                        ? "green"
                                        : value < 0
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {value > 0
                                    ? `+${value.toFixed(1)}`
                                    : value.toFixed(1)}{" "}
                                  {key === "weight" ? "kg" : "cm"}
                                </span>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                ) : (
                  <Text type="secondary">
                    Nėra pakankamai duomenų pokyčiui apskaičiuoti.
                  </Text>
                )}
              </div>
            </Col>

            <Col xs={24} md={12}>
              <Title level={4}>Viso laiko pokyčiai</Title>
              <div
                style={{
                  padding: "20px",
                  border: "1px solid #f0f0f0",
                  borderRadius: "8px",
                  background: "#fafafa",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.02)",
                }}
              >
                {totalChanges ? (
                  <Row gutter={[16, 16]}>
                    {Object.entries(totalChanges)
                      .filter(([key]) => icons[key]) // Filtruojame tik tuos, kurie turi ikoną
                      .map(([key, value]) => {
                        const Icon = icons[key]; // Ikonos komponentas
                        return (
                          <Col xs={12} sm={12} md={8} key={key}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                padding: "10px",
                                border: "1px solid #f0f0f0",
                                borderRadius: "6px",
                                background: "#ffffff",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.04)",
                              }}
                            >
                              {Icon && (
                                <div
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    backgroundColor: "#e0f4f1",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Icon color="#61988e" size="30px" />
                                </div>
                              )}

                              <div>
                                <strong
                                  style={{ fontSize: "12px", display: "block" }}
                                >
                                  {fieldNamesLT[key]}
                                </strong>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    color:
                                      value > 0
                                        ? "green"
                                        : value < 0
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {value > 0
                                    ? `+${value.toFixed(1)}`
                                    : value.toFixed(1)}{" "}
                                  {key === "weight" ? "kg" : "cm"}
                                </span>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                ) : (
                  <Text type="secondary">
                    Nėra pakankamai duomenų bendriems pokyčiams apskaičiuoti.
                  </Text>
                )}
              </div>
            </Col>
          </Row>

          <Title style={{ marginTop: 10 }} level={4}>
            Pokyčių lentelė
          </Title>
          <div
            style={{
              overflowX: "auto",
            }}
          >
            <Table
              columns={columns.map((col, index) => ({
                ...col,
                fixed: index === 0 ? "left" : undefined,
                width: index === 0 ? 150 : 100,
              }))}
              rowKey="id"
              dataSource={data}
              bordered
              pagination={false}
              scroll={{ x: "max-content" }}
              sticky
              summary={(pageData) => {
                if (pageData.length <= 1) return null;

                const initial = pageData[0];
                const latest = pageData[pageData.length - 1];

                const visibleKeys = columns.map((col) => col.key);

                const totals = Object.keys(initial)
                  .filter((key) => visibleKeys.includes(key))
                  .reduce((acc, key) => {
                    const value = (latest[key] || 0) - (initial[key] || 0);
                    const unit = key === "weight" ? "kg" : "cm";
                    acc[key] = {
                      value,
                      formatted: (
                        <span style={{ color: value >= 0 ? "green" : "red" }}>
                          {value >= 0
                            ? `+${value.toFixed(1)}`
                            : value.toFixed(1)}{" "}
                          {unit}
                        </span>
                      ),
                    };
                    return acc;
                  }, {});

                return (
                  <Table.Summary.Row>
                    {columns.map((col, index) => (
                      <Table.Summary.Cell
                        key={index}
                        fixed={index === 0 ? "left" : undefined} // Užtikriname, kad pirmas stulpelis būtų fiksuotas
                        sticky
                        style={
                          index === 0
                            ? {
                                left: 0,
                                zIndex: 2,
                                background: "#fff",
                              }
                            : { background: "#fff" }
                        }
                      >
                        {index === 0 ? (
                          <strong>Pokytis:</strong>
                        ) : (
                          totals[col.dataIndex]?.formatted || "0.0"
                        )}
                      </Table.Summary.Cell>
                    ))}
                  </Table.Summary.Row>
                );
              }}
            />
          </div>
        </>
      )}
      <Modal
        visible={isInitialModalVisible}
        onCancel={handleInitialModalCancel}
        footer={null}
        width="100%" // Modal užima visą plotį
        bodyStyle={{
          maxWidth: "700px",
          margin: "0 auto", // Centruojame modal turinį
          padding: "20px",
        }}
      >
        <h2>Pridėti pradinę apimtį</h2>
        <Alert
          type="info"
          message="Sužinokite, kaip tinkamai atlikti matavimus. Įkelkite nuotraukas, kad galėtumėte sekti savo progresą ir pasiekti užsibrėžtų tikslų!"
          description={
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => setIsInfoModalVisible(true)} // Atidarome informacinį modalą
            >
              Peržiūrėti instrukciją
            </Button>
          }
          showIcon
          style={{ marginBottom: "20px" }}
        />
        <Form onFinish={handleAddInitialValues} layout="vertical">
          <Row gutter={[16, 16]}>
            {/* Kairė pusė - įvedimo laukai */}
            <Col xs={24} md={16}>
              {/* "Svoris" - pilnos eilutės laukas */}
              <Form.Item
                label="Svoris (kg)"
                name="weight"
                initialValue={0} // Pradinė reikšmė
                rules={[{ required: true, message: "Įveskite svorį!" }]}
              >
                <Input type="number" placeholder="Įveskite svorį (kg)" />
              </Form.Item>

              {/* Kiti laukai - po du vienoje eilutėje ant didelio ekrano, pilnai ant mažo */}
              <Row gutter={16}>
                {[
                  { label: "Krūtinė (cm)", name: "chest" },
                  { label: "Pečiai (cm)", name: "shoulders" },
                  { label: "Bicepsas (įtemptas) (cm)", name: "biceps" },
                  { label: "Talija (cm)", name: "waist" },
                  { label: "Pilvas (cm)", name: "belly" },
                  { label: "Klubai (cm)", name: "hips" },
                  { label: "Šlaunis (cm)", name: "thigh" },
                  { label: "Blauzda (cm)", name: "calf" },
                ].map((field, index) => (
                  <Col xs={24} md={12} key={index}>
                    <Form.Item
                      label={field.label}
                      name={field.name}
                      rules={[
                        {
                          required: true, // Privalomas laukas
                          message: `Įveskite ${field.label.toLowerCase()}!`,
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder={`Įveskite ${field.label.toLowerCase()}`}
                      />
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </Col>

            {/* Dešinė pusė - nuotraukų įkėlimas */}
            <Col xs={24} md={8}>
              <Title level={5}>Nuotraukos</Title>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {["Iš priekio", "Iš šono", "Iš nugaros"].map((label, index) => (
                  <Form.Item
                    key={index}
                    label={label}
                    name={`image${index}`}
                    valuePropName="fileList" // Naudojame "fileList", nes tai būtina Upload komponentui
                    getValueFromEvent={(e) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e?.fileList;
                    }}
                  >
                    <Upload
                      name="file" // Failo parametro pavadinimas užklausai
                      listType="picture" // Rodo failo peržiūrą (thumbnail)
                      beforeUpload={() => false} // Blokuojame automatinį įkėlimą
                      accept="image/*" // Tik vaizdo failai
                    >
                      <Button icon={<UploadOutlined />}>
                        Pasirinkite failą
                      </Button>
                    </Upload>
                  </Form.Item>
                ))}
              </div>
            </Col>
          </Row>

          {/* Mygtukas pateikti */}
          <Button
            className="primary-button"
            htmlType="submit"
            style={{ width: "100%", marginTop: "20px" }}
          >
            Pridėti
          </Button>
        </Form>

        {/* Papildomas modalas instrukcijai */}
        <Modal
          title="Kaip tinkamai išmatuoti?"
          visible={isInfoModalVisible}
          onCancel={() => setIsInfoModalVisible(false)}
          footer={null}
          width="600px"
        >
          <div style={{ textAlign: "center" }}>
            {/* Pateiktas paveikslėlis */}
            <img
              src="https://lazyfit.lt/wp-content/uploads/2024/11/information.png"
              alt="Instrukcijų nuotrauka"
              style={{ width: "100%", marginBottom: "20px" }}
            />
            <p>
              Norėdami tinkamai išmatuoti, naudokite matavimo juostą ir
              laikykitės šių instrukcijų:
            </p>
            <ul style={{ textAlign: "left" }}>
              <li>
                Krūtinė: matavimo juostą laikykite horizontaliai per plačiausią
                krūtinės vietą.
              </li>
              <li>
                Bicepsas (įtemptas): matavimo juostą laikykite ties rankos
                viduriu.
              </li>
              <li>
                Talija: matavimo juostą laikykite aplink siauriausią juosmens
                vietą.
              </li>
              <li>
                Klubai: matavimo juostą laikykite per plačiausią klubų vietą.
              </li>
              <li>Šlaunis: išmatuokite plačiausią šlaunies dalį.</li>
              <li>Blauzda: išmatuokite plačiausią blauzdos dalį.</li>
            </ul>
          </div>
        </Modal>
      </Modal>
      <Modal
        visible={isWeeklyModalVisible}
        onCancel={handleWeeklyModalCancel}
        footer={null}
        width="100%" // Modal užima visą plotį
        bodyStyle={{
          maxWidth: "700px",
          margin: "0 auto", // Centruojame modal turinį
          padding: "20px",
        }}
      >
        <h2>Pridėti matavimus</h2>
        <Alert
          type="info"
          message="Įveskite savo kūno matavimo duomenis. Tai padės stebėti jūsų progresą ir įvertinti pasiektus rezultatus!"
          description={
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => setIsInfoModalVisible(true)} // Atidarome informacinį modalą
            >
              Peržiūrėti instrukciją
            </Button>
          }
          showIcon
          style={{ marginBottom: "20px" }}
        />
        <Form onFinish={handleAddWeeklyValues} layout="vertical">
          <Row gutter={[16, 16]}>
            {/* Kairė pusė - įvedimo laukai */}
            <Col xs={24} md={16}>
              <Form.Item
                label="Svoris (kg)"
                name="weight"
                rules={[{ required: true, message: "Įveskite svorį!" }]}
              >
                <Input type="number" placeholder="Įveskite svorį (kg)" />
              </Form.Item>

              {/* Matavimo laukai */}
              <Row gutter={16}>
                {[
                  { label: "Krūtinė (cm)", name: "chest" },
                  { label: "Pečiai (cm)", name: "shoulders" },
                  { label: "Bicepsas (įtemptas) (cm)", name: "biceps" },
                  { label: "Talija (cm)", name: "waist" },
                  { label: "Pilvas (cm)", name: "belly" },
                  { label: "Klubai (cm)", name: "hips" },
                  { label: "Šlaunis (cm)", name: "thigh" },
                  { label: "Blauzda (cm)", name: "calf" },
                ].map((field, index) => (
                  <Col xs={24} md={12} key={index}>
                    <Form.Item
                      label={field.label}
                      name={field.name}
                      rules={[
                        {
                          required: true,
                          message: `Įveskite ${field.label.toLowerCase()}!`,
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        placeholder={`Įveskite ${field.label.toLowerCase()}`}
                      />
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </Col>

            {/* Dešinė pusė - nuotraukų įkėlimas */}
            <Col xs={24} md={8}>
              <Title level={5}>Nuotraukos</Title>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {["Iš priekio", "Iš šono", "Iš nugaros"].map((label, index) => (
                  <Form.Item
                    key={index}
                    label={label}
                    name={`image${index}`}
                    valuePropName="fileList" // Reikia Upload komponentui
                    getValueFromEvent={(e) =>
                      Array.isArray(e) ? e : e?.fileList
                    }
                  >
                    <Upload
                      name="file"
                      listType="picture"
                      beforeUpload={() => false} // Blokuojame automatinį įkėlimą
                      accept="image/*"
                    >
                      <Button icon={<UploadOutlined />}>
                        Pasirinkite failą
                      </Button>
                    </Upload>
                  </Form.Item>
                ))}
              </div>
            </Col>
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", marginTop: "20px" }}
          >
            Pridėti
          </Button>
        </Form>

        <Modal
          title="Kaip tinkamai išmatuoti?"
          visible={isInfoModalVisible}
          onCancel={() => setIsInfoModalVisible(false)}
          footer={null}
          width="600px"
        >
          <div style={{ textAlign: "center" }}>
            {/* Pateiktas paveikslėlis */}
            <img
              src={image} // Nurodykite tinkamą paveikslėlio kelią
              alt="Instrukcijų nuotrauka"
              style={{ width: "100%", marginBottom: "20px" }}
            />
            <p>
              Norėdami tinkamai išmatuoti, naudokite matavimo juostą ir
              laikykitės šių instrukcijų:
            </p>
            <ul style={{ textAlign: "left" }}>
              <li>
                Krūtinė: matavimo juostą laikykite horizontaliai per plačiausią
                krūtinės vietą.
              </li>
              <li>
                Bicepsas (įtemptas): matavimo juostą laikykite ties rankos
                viduriu.
              </li>
              <li>
                Talija: matavimo juostą laikykite aplink siauriausią juosmens
                vietą.
              </li>
              <li>
                Klubai: matavimo juostą laikykite per plačiausią klubų vietą.
              </li>
              <li>Šlaunis: išmatuokite plačiausią šlaunies dalį.</li>
              <li>Blauzda: išmatuokite plačiausią blauzdos dalį.</li>
            </ul>
          </div>
        </Modal>
      </Modal>
      <Modal
        visible={deleteConfirmVisible}
        onCancel={handleDeleteCancel}
        onOk={handleDelete} // Naudoja handleDelete funkciją
        title="Patvirtinkite ištrynimą"
        okText="Ištrinti"
        cancelText="Atšaukti"
      >
        <p>Ar tikrai norite ištrinti šį matavimą?</p>
      </Modal>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={currentImage} // Dabartinė nuotrauka
          nextSrc={images[(currentImageIndex + 1) % images.length]} // Kita nuotrauka
          prevSrc={
            images[(currentImageIndex - 1 + images.length) % images.length]
          } // Ankstesnė nuotrauka
          onCloseRequest={closeLightbox} // Uždaro Lightbox
          onMovePrevRequest={moveToPrevImage} // Perjungia į ankstesnę
          onMoveNextRequest={moveToNextImage} // Perjungia į kitą
        />
      )}
      {isGalleryLightboxOpen && (
        <Lightbox
          mainSrc={currentGalleryImage} // Dabartinė nuotrauka
          nextSrc={
            allImages[(currentGalleryImageIndex + 1) % allImages.length]?.url
          }
          prevSrc={
            allImages[
              (currentGalleryImageIndex - 1 + allImages.length) %
                allImages.length
            ]?.url
          }
          onCloseRequest={closeGalleryLightbox} // Uždaro Lightbox
          onMovePrevRequest={moveToPrevGalleryImage} // Ankstesnė nuotrauka
          onMoveNextRequest={moveToNextGalleryImage} // Kita nuotrauka
        />
      )}
    </div>
  );
};

export default MeasurementTable;
